@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('../fonts/SourceSansPro-Regular/SourceSansPro-Regular.woff2') format('woff2'),
  url('../fonts/SourceSansPro-Regular/SourceSansPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2'),
  url('../fonts/Inter/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter/Inter-LightBETA.woff2') format('woff2'),
  url('../fonts/Inter/Inter-LightBETA.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter-Extra-Light';
  src: url('../fonts/Inter/Inter-ExtraLightBETA.woff2') format('woff2'),
  url('../fonts/Inter/Inter-ExtraLightBETA.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
