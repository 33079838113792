.DayPicker > div {
    display: flex;
    flex-direction: column-reverse;
}
.CalendarMonth_caption {
    font-family: 'SourceSansPro-Regular';
    font-size: 15px;
    font-weight: bold;
    color: #43425d;
    padding-bottom: 45px;
}
.DayPickerNavigation_button__default {
    border: 1px solid transparent;
}
.DayPickerNavigation_svg__horizontal {
    fill: #808495;
}
.DayPickerNavigation_svg__horizontal > path {
    width: 2px;
}
.DayPicker_weekHeader_ul {
    border-bottom: 2px solid rgba(190, 190, 190, 0.5);
}
.DayPicker_weekHeader_li {
    font-family: 'SourceSansPro-Regular';
    font-weight: bold;
    font-size: 15px;
}
.CalendarDay__default {
    color: #43425d;
}
.CalendarDay__default, .CalendarDay__default:hover, .CalendarDay__default:active {
    border: none;
}
.CalendarDay__blocked_out_of_range {
    color: #CACCCD;
}
.CalendarDay__blocked_out_of_range:hover, .CalendarDay__blocked_out_of_range:active {
    border: none;
}
.CalendarDay__today, .CalendarDay__today:hover, .CalendarDay__today:active {
    font-weight: bold;
    color: #3B86FF;
    border-radius: 50%;
    background-color: rgba(59, 134, 255, 0.2);
}
.CalendarDay__selected_span {
    background-color: rgba(59, 134, 255, 0.2);
    border-radius: unset;
}
.CalendarDay__selected_span:hover, .CalendarDay__selected_start:hover, .CalendarDay__selected_end:hover {
    background-color: rgba(59, 134, 255, 0.5);
    border: none;
}
.CalendarDay__selected_start {
    background-color: rgba(59, 134, 255, 0.5);
    border-radius: unset;
}
.CalendarDay__selected_end {
    background-color: rgba(59, 134, 255, 0.5);
    border-radius: unset;
    /*border-radius: 50%;*/
}
.DateInput_input {
    font-family: 'SourceSansPro-Regular';
    font-weight: normal;
    font-size: 13px;
    color: #4d4f5c;
    text-align: center;
    padding: 8px 11px 6px;
}
.DateInput_input__focused {
    border-bottom-color: rgba(59, 134, 255, 1);
}
.DayPickerKeyboardShortcuts_show__bottomRight::before, .DayPickerKeyboardShortcuts_show__topRight::before {
    border-right-color: rgba(59, 134, 255, 0.8);
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before, .DayPickerKeyboardShortcuts_show__topRight:hover::before {
    border-right-color: #3B86FF;
}
.DateRangePicker {
    display: block;
    width: 100%;
}
.DateRangePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DateRangePickerInput__withBorder {
    border: solid 1px #d7dae2;
    border-radius: 4px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 1200px) {
    .DateRangePicker {
        display: inline-block;
        width: auto;
    }
    .DateRangePickerInput {
        display: inline-block;
        width: auto;
    }
}
